import React, { useState, useEffect } from "react";
import Software from "../types/software";
import { generateLicense, getSoftware } from "../services/api.service";
import LicenseTxtModel from "../types/licenseTxtModel";
import { saveAs } from 'file-saver';
import JSZip from "jszip";
import { anErrorOccurred, softwareNotFound } from "../services/errors";
import { getErrorMessage } from "../services/utils";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Identity from "../types/identity";

export interface LicenseGeneratorProps {
  identity?: Identity | null
};

const LicenseGenerator: React.FC<LicenseGeneratorProps> = (props: LicenseGeneratorProps) => {
  const navigate: NavigateFunction = useNavigate();
  const [softwareList, setSoftwareList] = useState<Software[]>([]);
  const [count, setCount] = useState<number | null>(null);
  const [licenses, setLicenses] = useState<LicenseTxtModel[]>([]);
  const [selectedSoftware, setSelectedSoftware] = useState<number>(0);
  const [output, setOutput] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<boolean>(false);

  useEffect(() => {
    if (props.identity) {
      if (!props.identity.isAdmin) {
        navigate("/login");
      }
      else {
        softwareRequest();
      }
    }
  }, [props.identity]);

  useEffect(() => {
    toOutput();
  }, [licenses]);

  useEffect(() => {
    setLoading(false);
  }, [softwareList]);

  const softwareRequest = async () => {
    setLoading(true);
    try {
      const response = await getSoftware();
      if (response.data && response.data.length) {
        setError('');
        setSelectedSoftware(response.data[0].id);
        setSoftwareList(response.data);
      } else {
        setSoftwareList([]);
        setError(softwareNotFound);
      }
    } catch (e) {
      setError(getErrorMessage(e));
    }
    setLoading(false);
  };

  const onChangeSoftware = (event: any) => {
    setSelectedSoftware(event.target.value);
  }

  const validateCount = (count: number): boolean => {
    return count > 0 && count <= 1000;
  }

  const onCountChange = (event: any) => {
    const value = event.target.value;
    if (validateCount(value)) {
      setCount(value);
      setValidationError(false);
    } else {
      setCount(null);
      setValidationError(true);
    }
  }

  const generate = async () => {
    if (!count) {
      setValidationError(true);
      return;
    }
    setLoading(true);
    try {
      const response = await generateLicense(count!, selectedSoftware);
      if (response.data) {
        setLicenses(response.data);
      } else {
        setError(anErrorOccurred);
      }
    } catch (e) {
      setError(getErrorMessage(e));
    }
    setLoading(false);
  }

  const toOutput = () => {
    let output = '';
    licenses.forEach(license => {
      output += getTxtForEmail(license);
    });
    setOutput(output);
  }

  const getTxtForEmail = (license: LicenseTxtModel): string => {
    return `${license.softwareName} License\n\nLicense Key:\n${license.licenseKey}\nSoftware Download Link:\n${license.softwareDownloadLink}\n\n`
  }

  const getTxtForPrint = (): string => {
    const tab = '\t';
    let result = `Software name${tab}Download link${tab}License key\n`;
    licenses.forEach(license => {
      result += `${license.softwareName}${tab}${license.softwareDownloadLink}${tab}${license.licenseKey}\n`;
    });
    return result;
  }

  const pad = (id: number) => {
    let result = id.toString();
    while (result.length < 10) result = "0" + result;
    return result;
  }

  const exportTxtForPrint = () => {
    const fileContent = getTxtForPrint();
    const file = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
    saveAs(file, 'LicensesForPrint.txt');
  }

  const exportTxtForEmail = async () => {
    let zip = new JSZip();
    licenses.forEach(license => {
      const fileName = `${license.softwareName} License ${pad(license.licenseId)}.txt`;
      const fileContent = getTxtForEmail(license);
      const file = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
      zip.file(fileName, file);
    });
    const zipContent = await zip.generateAsync({ type: "blob" })
    saveAs(zipContent, 'LicensesForEmails.zip');
  }

  return (
    <div className="container">
      <div className="row justify-content-md-center">
        {error && <div className="col-6">
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        </div>}
      </div>
      <div className="row justify-content-around">
        <div className="col-3">
          <div className="row">
            <div className="col">
              <label className="form-label">Software</label>
              <select className="form-select" aria-label="Software" onChange={onChangeSoftware} value={selectedSoftware}>
                {softwareList.map(software => (
                  <option key={software.id} value={software.id}>
                    {software.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label">License count</label>
              <input id="license-count" type="number" className="form-control" placeholder="" aria-label="License count" onChange={onCountChange} />
              <div className={validationError ? 'invalid-feedback validation-message' : 'invalid-feedback'}>
                Please enter a value in the range 1...1000.
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6 d-grid">
              <button type="submit" className="btn btn-primary" onClick={generate} disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                )}
                <span>Generate</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col">
              <label className="form-label">Output</label>
              <textarea id="license-output" className="form-control" placeholder="" aria-label="Output" defaultValue={output} />
            </div>
          </div>
          <br />
          <div className="row justify-content-between align-items-start">
            <div className="col-5 d-grid">
              <button type="submit" className="btn btn-primary" disabled={loading || !licenses.length} onClick={exportTxtForEmail}>
                <span>Export TXT for e-mail</span>
              </button>
            </div>
            <div className="col-5 d-grid">
              <button type="submit" className="btn btn-primary" disabled={loading || !licenses.length} onClick={exportTxtForPrint}>
                <span>Export TXT for print</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseGenerator;
